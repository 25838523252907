@font-face {
	font-family: Montserrat;
	src: url("./Montserrat-Regular.ttf");
}

$text-color: rgb(114, 117, 121);
$primary-color: rgb(255, 248, 220);

*,
*::before,
*,
*::after {
	box-sizing: border-box;
}
body {
	font-family: Montserrat, sans-serif;
	padding: 0;
	margin: 0;
	overflow-x: hidden;
}
.container {
	background-image: url("./components/galery/galery/background.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: repeat;
}
//MENU
nav {
	background-color: rgb(255, 248, 220);
	max-width: 100%;
	margin: 0 auto;
}

.menu {
	display: flex;
	flex-direction: column;
	&__items {
		list-style-type: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		padding: 0;
		row-gap: 1.6rem;
	}
	&__logo {
		width: 110px;
	}
	&__link {
		color: black;
		font-size: 1.2rem;
		text-decoration: none;
		text-transform: uppercase;
	}
	&__icon {
		position: relative;
		left: 180px;
		color: black;
		width: 4.5rem;
		border: 1px solid black;
		padding: 0.8rem 1.3rem;
		border-radius: 3px;
		margin-bottom: 28px;
		cursor: pointer;
		transition: all 0.3s ease-in;
	}
	&__last_element {
		padding-bottom: 2.5rem;
	}
}
.collapsible_content {
	overflow: hidden;
	max-height: 0;
	transition: all 0.3s ease-in;
}
.collapsible--expanded .collapsible_content {
	max-height: 300vh;
}
@media screen and (min-width: 1200px) {
	.menu {
		display: flex;
		justify-content: center;
		flex-direction: row;
		gap: 60px;
		&__logo {
			width: 100px;
		}
		&__items {
			flex-direction: row;
			justify-content: center;
			column-gap: 4rem;
			padding: 3rem 0;
			margin-right: 3rem;
		}
		&__last_element {
			padding-bottom: 0;
		}
		&__icon {
			display: none;
		}
	}
}
a:hover {
	color: rgb(255, 222, 173);
}

.home {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 5vh;
	margin-bottom: 5vh;
	&__heading {
		text-align: center;
		margin-top: 70px;
		margin-bottom: 100px;
	}
	&__heading--background {
		background-image: url("./components/galery/gabinet.jpg");
		height: 90vh;
		position: relative;
		bottom: 140px;
		width: 100%;
		background-position: center;
		background-position-x: -450px;
		background-size: auto;
		zoom: 35%;
		align-items: center;
		color: white;
	}
	&__images img {
		border-radius: 0.7rem;
		height: 400px;
	}
	&__images {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__description--about {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 60px;
		a {
			margin-bottom: 10px;
		}
		p {
			text-align: left;
			margin: 0 45px 0 85px;
			color: rgb(114, 117, 121);
			font-size: 18px;
			line-height: 30px;
		}
		h2 {
			margin: 25px 0 15px 0;
			text-align: center;
			font-weight: 700;
		}
	}
	&__description--about_down {
		margin-left: 85px;
	}
	&__description--about_down-a {
		text-decoration: none;
		margin-left: 85px;
		color: rgb(210, 180, 140);
	}
	&__description--gab {
		text-align: center;
		color: $text-color;
		margin: 0 25px 0 25px;
	}
	&__table {
		border-spacing: 0;
		width: 57%;
		font-size: 15px;
	}
	&__table-item:first-child {
		border: none;
		background-color: rgb(255, 248, 220);
	}
	&__images {
		display: flex;
		justify-content: center;
		flex-direction: row;
		gap: 30px;
	}
	&__table-item td {
		border-bottom: 2px solid #dcdcdc;
		padding: 10px;
	}
	&__table-item td:first-child {
		border-left: 2px solid #dcdcdc;
	}
	&__table-item td:last-child {
		border-right: 2px solid #dcdcdc;
	}
	&__table-item:first-child td {
		border-top: 2px solid #dcdcdc;
		font-weight: 700;
	}
	&__under-table {
		margin-top: 2vh;
		width: 57%;
		text-align: center;
		font-weight: 700;
	}
	&__section {
		margin: 0 20px 0 20px;
	}
	&__box section {
		border: 3px solid rgb(222, 184, 135);
		width: 80%;
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-bottom: 50px;
		margin-top: 50px;
	}
	&__box .item--first {
		margin-bottom: 30px;
	}
	&__box .item--last {
		margin-bottom: 30px;
	}
	.faq {
		margin-top: 10px;
		margin-bottom: 20px;
	}
	&__heading--last {
		line-height: 35px;
		animation-name: slideInLeft;
		animation-duration: 2s;
		animation-timing-function: linear;
		animation-direction: normal;
		font-size: 65px;
		position: absolute;
		top: 350px;
		min-height: 100%;
		left: 30px;
	}
	&__heading--first {
		animation-duration: 2s;
		animation-direction: normal;
		animation-timing-function: linear;
		animation-name: slideInRight;
		font-size: 65px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: hidden;
		overflow: hidden;
		top: 250px;
		left: 30px;
	}
	&__heading--button {
		border: none;
		width: 30%;
		position: absolute;
		top: 480px;
		left: 30px;
		padding: 20px;
		border-radius: 0.625rem;
		background-color: rgb(255, 248, 220);
		font-weight: 700;
		color: rgb(52, 48, 48);
		font-size: 27px;
		animation-duration: 4s;
		animation-timing-function: ease-out;
		animation-direction: normal;
		animation-name: slideInLeft2;
		animation-fill-mode: forwards;
	}
	&__description {
		font-size: 13px;
		text-align: center;
	}
	&__images--building {
		display: flex;
		justify-content: center;
		margin-top: 7vh;
		margin-bottom: 40px;
		img {
			width: 90%;
			border-radius: 0.625rem;
		}
	}
	&__icons section {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 10vh;
		background-color: $primary-color;
		border-radius: 0.625rem;
	}
	&__icons {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top: 6vh;
		margin-bottom: 3vh;
		gap: 30px;
	}
	&__icons--email {
		margin-top: 8px;
		width: 50px;
		height: 50px;
		background-color: $primary-color;
	}
	&__icons--phone {
		margin-top: 18px;
		width: 50px;
		height: 50px;
		background-color: $primary-color;
	}
	&__icons--address {
		width: 50px;
		height: 50px;
		margin-top: 8px;
		background-color: $primary-color;
	}
	&__icons--description {
		position: relative;
		bottom: 10px;
		height: 50px;
		padding: 20px;
		font-weight: 700;
		font-size: 12px;
		span {
			font-size: 15px;
		}
	}
	&__information {
		text-align: center;
		margin: 0 5px 0 5px;
	}
	&__sec {
		display: flex;
		flex-direction: column;
		width: 70%;
	}
	&__form {
		position: relative;
		bottom: 25px;
		right: 55px;
		height: 100%;
		text-align: left;
		input {
			margin: 5px 0 5px 0;
			padding: 10px;
			border-radius: 10px;
			width: 140%;
			border: 1px solid $text-color;
			font-size: 18px;
		}
		textarea {
			margin: 5px 0 5px 0;
			border-radius: 10px;
			width: 140%;
			height: 120px;
			border: 1px solid $text-color;
			font-size: 15px;
		}
		span {
			color: red;
		}
	}
	&__map {
		position: relative;
		right: 50px;
	}
	.home_heading {
		margin-bottom: 20px;
		margin-top: 0;
		border-bottom: 3px solid black;
	}
	&__description--home {
		text-align: center;
		color: black;
		width: 90%;
		line-height: 30px;
	}
	&__icons--picture {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__icons--element {
		width: 30%;
		position: relative;
		left: 120px;
	}
	&__icons--down {
		font-size: 25px;
		margin-left: 128px;
		font-weight: 700;
	}
	&__icons--down--last {
		font-size: 25px;
		margin-left: 120px;
		font-weight: 700;
	}
	&__icons--down--second {
		font-size: 25px;
		margin-left: 120px;
		font-weight: 700;
	}
	&__description--home {
		text-align: center;
		color: black;
		width: 90%;
		line-height: 35px;
	}
	&__icons--fb {
		width: 25%;
		position: relative;
		left: 140px;
	}
}
.home .home__icons {
	margin-top: 45px;
	span {
		font-size: 19px;
	}
	p {
		margin-top: -4px;
		text-align: center;
		font-size: 21px;
	}
}
iframe {
	width: 350px;
	height: 400px;
}
.heading-form {
	position: relative;
	left: 50px;
}

@keyframes slideInLeft {
	from {
		overflow: hidden;
		height: 100%;
		transform: translateX(-1500px);
	}
	to {
		overflow: visible;
		transform: translateX(0);
	}
}
@keyframes slideInRight {
	from {
		overflow-x: hidden;
		overflow-y: hidden;
		transform: translateX(1500px);
	}
	to {
		overflow: visible;
		transform: translateX(0);
	}
}
@keyframes slideInLeft2 {
	from {
		overflow: hidden;
		transform: translateX(-1850px);
	}
	to {
		overflow: visible;
		transform: translateX(0);
	}
}
// tablet //
@media screen and (min-width: 768px) and (max-width: 1199px) {
	.menu {
		&__icon {
			color: black;
			width: 4.5rem;
			border: 1px solid black;
			padding: 0.8rem 1.3rem;
			border-radius: 3px;
			margin-left: 560px;
			margin-bottom: 28px;
			cursor: pointer;
			transition: all 0.3s ease-in;
		}
	}
}
// desktop //
@media screen and (min-width: 1200px) {
	.home {
		&__table {
			border-spacing: 0;
			width: 57%;
			font-size: 17px;
		}
		&__table-item:first-child {
			border: none;
			background-color: rgb(255, 248, 220);
		}
		&__images {
			display: flex;
			flex-direction: row;
			gap: 30px;
		}
		&__table-item td {
			border-bottom: 2px solid #dcdcdc;
			padding: 10px;
		}
		&__table-item td:first-child {
			border-left: 2px solid #dcdcdc;
		}
		&__table-item td:last-child {
			border-right: 2px solid #dcdcdc;
		}
		&__table-item:first-child td {
			border-top: 2px solid #dcdcdc;
			font-weight: 700;
		}
		&__under-table {
			margin-top: 2vh;
			width: 57%;
			text-align: center;
			font-weight: 700;
			margin-bottom: 40vh;
		}
		&__box--about {
			display: flex;
			flex-direction: row;
		}
		&__images img {
			width: 290px;
			height: 387px;
			margin-left: 200px;
		}
		&__description--about {
			margin-bottom: 130px;
			margin-top: -25px;
			p {
				margin-left: 43px;
				color: $text-color;
			}
			h2 {
				text-align: left;
				margin-top: 18px;
				margin-left: 43px;
			}
			a {
				margin-left: 40px;
			}
		}
		&__description--about_down {
			margin-left: 40px;
		}
		&__description--gab {
			text-align: center;
			color: $text-color;
			width: 57%;
		}
		.image_of_person {
			width: 350px;
			height: 420px;
		}
		&__section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			align-self: center;
			align-content: center;
		}
		&__heading {
			text-align: center;
		}
		&__box {
			display: flex;
			flex-direction: row;
		}
		&__box section {
			border: 3px solid rgb(222, 184, 135);
			width: 80%;
			display: flex;
			flex-direction: column;
			text-align: center;
			p {
				font-size: 17px;
				padding: 0 30px 0 30px;
			}
		}
		&__box .item--last {
			width: 500px;
			height: 500px;
			margin: 0 100px 0 0;
		}
		&__box .item--first {
			margin: 0 100px 0 150px;
			width: 500px;
			height: 500px;
		}
		.description {
			display: flex;
			justify-content: center;
			text-align: center;
			width: 70%;
		}
		.faq {
			margin-top: 10px;
			margin-bottom: 20px;
		}
		&__images--building {
			display: flex;
			justify-content: center;
			margin-top: 7vh;
			margin-bottom: 50px;
			img {
				width: 40%;
				border-radius: 0.625rem;
			}
		}
		&__icons section {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 10vh;
			background-color: $primary-color;
			border-radius: 0.625rem;
		}
		&__icons {
			display: grid;
			justify-content: center;
			grid-template-columns: repeat(3, 600px);
			align-items: center;
			margin-top: 6vh;
			margin-bottom: 3vh;
		}
		&__icons--email {
			top: 0px;
			width: 50px;
			height: 50px;
			position: relative;
			background-color: $primary-color;
		}
		&__icons--phone {
			position: relative;
			top: -5px;
			width: 50px;
			height: 50px;
			background-color: $primary-color;
		}
		&__icons--address {
			position: relative;
			width: 50px;
			height: 50px;
			top: 0px;
			background-color: $primary-color;
		}
		&__icons--description {
			position: relative;
			bottom: 25px;
			height: 50px;
			padding: 35px;
			font-weight: 700;
			span {
				font-size: 25px;
			}
		}
		&__sec {
			display: flex;
			flex-direction: row;
			width: 80%;
		}
		&__form {
			position: relative;
			bottom: 25px;
			height: 100%;
			width: 25%;
			text-align: left;
			input {
				margin: 5px 0 5px 0;
				padding: 10px;
				border-radius: 10px;
				width: 140%;
				border: 1px solid $text-color;
				font-size: 18px;
			}
			textarea {
				margin: 5px 0 5px 0;
				border-radius: 10px;
				width: 140%;
				height: 120px;
				border: 1px solid $text-color;
				font-size: 15px;
			}
			span {
				color: red;
			}
		}
		&__map &__heading {
			position: relative;
			bottom: 40px;
		}
		&__heading--background {
			background-image: url("./components/galery/gabinet.jpg");
			height: 90vh;
			position: relative;
			bottom: 50px;
			background-position-x: 0px;
			background-size: cover;
			background-repeat: no-repeat;
			align-items: center;
			zoom: normal;
			color: white;
		}
		&__heading--first {
			animation-name: slideInRight;
			animation-duration: 1.5s;
			animation-timing-function: linear;
			animation-direction: normal;
			font-size: 65px;
			animation-fill-mode: forwards;
			position: absolute;
			top: 320px;
			left: 200px;
			animation-delay: 0s;
		}
		&__heading--last {
			margin: auto 0;
			animation-duration: 2.5s;
			animation-timing-function: linear;
			animation-name: slideInLeft;
			animation-fill-mode: forwards;
			position: absolute;
			height: 100%;
			font-size: 50px;
			top: 480px;
			left: 200px;
		}
		&__heading--button {
			border: none;
			width: 15%;
			top: 320px;
			position: absolute;
			left: 200px;
			padding: 15px;
			top: 530px;
			border-radius: 0.3rem;
			background-color: rgb(255, 248, 220);
			font-weight: 700;
			color: rgb(52, 48, 48);
			font-size: 14px;
			animation-duration: 3s;
			animation-timing-function: linear;
			animation-direction: normal;
			animation-name: slideInLeft2;
			animation-fill-mode: forwards;
		}
		&__heading--button:hover {
			color: white;
			background-color: rgb(222, 184, 135);
		}
		&__icons--picture {
			display: flex;
			flex-direction: row;
			justify-content: center;
			width: 60%;
			margin-left: 170px;
		}
		&__icons--element {
			left: 0px;
			width: 35%;
		}
		&__icons--down {
			font-size: 25px;
			margin-left: 11px;
		}
		&__icons--down--last {
			font-size: 25px;
			font-weight: 700;
			margin-left: -3px;
		}
		&__icons--down--second {
			font-size: 25px;
			font-weight: 700;
			margin-left: 20px;
		}
		&__description--home {
			text-align: center;
			color: black;
			width: 50%;
			line-height: 30px;
		}
		&__icons--fb {
			width: 25%;
			position: relative;
			left: 200px;
		}
	}
	iframe {
		width: 550px;
		height: 510px;
	}
	.home_heading {
		margin-bottom: 50px;
		margin-top: 0px;
		border-bottom: 3px solid black;
	}
	.home_page {
		margin-top: 70px;
		display: flex;
		justify-content: center;
	}
	.large {
		font-size: 35px;
	}
	.second {
		width: 40%;
		position: relative;
		top: -2px;
	}
	.last {
		position: relative;
		top: 7px;
	}
	.home .home__icons {
		margin-top: 0;
		display: flex;
		flex-direction: row;
		p {
			text-align: center;
			margin-top: 10px;
			font-size: 19px;
		}
	}
}

///

.form-map {
	background-color: rgb(255, 248, 220);
	display: grid;
	grid-template-columns: repeat(2, 550px);
	justify-content: center;
	padding-bottom: 10vh;
	&_heading2 {
		text-align: center;
		text-transform: capitalize;
		margin-bottom: 50px;
	}
	&_heading1 {
		text-align: center;
		text-transform: capitalize;
	}
	&_send,
	&_reset {
		margin-top: 20px;
		width: 30%;
		border: none;
		background-color: rgb(222, 184, 135);
		padding: 10px;
		border-radius: 0.625rem;
		font-weight: 700;
		color: white;
	}
	&_reset {
		margin-left: 105px;
	}
}
.form-map section:first-child {
	width: 100%;
}
section:nth-child(2) {
	margin: auto;
	padding: 0;
	font-weight: 700;
	font-size: 16px;
	padding: 10px;
	border-radius: 0.8rem;
	span {
		font-size: 25px;
	}
}
section:nth-child(2) .icons_description {
	margin-top: -20px;
}
section:nth-child(3) {
	margin: auto;
	font-weight: 700;
	font-size: 16px;
	padding: 10px;
	border-radius: 0.8rem;
	span {
		font-size: 25px;
	}
}
section:nth-child(3) .icons_description {
	margin-top: -15px;
}
section:first-child {
	margin: auto;
	padding: 0;
	font-weight: 700;
	font-size: 16px;
	padding: 10px;
	border-radius: 0.8rem;
	span {
		font-size: 25px;
	}
}
section:first-child .icons_description {
	margin-top: -15px;
}
///
hr {
	margin-top: 70px;
	border: 1px solid rgb(222, 184, 135);
}

footer {
	width: 100%;
	padding: 10px;
	margin: 0;
	background-color: rgb(210, 180, 140);
	text-align: center;
	color: white;
}

.scroll-to-top {
	bottom: 11px;
	right: 11px;
	position: fixed;
	z-index: 101;
	img {
		width: 30px;
		height: 40px;
	}
	button {
		border: none;
		background-color: rgb(222, 184, 135);
		border-radius: 0.625rem;
	}
}
.article-question article {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 20px;
	transition: opacity 0.5s ease-in-out;
}
.article-question p {
	text-align: center;
	font-size: 17px;
	margin: 0 50px 0 50px;
}
